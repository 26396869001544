/* eslint-disable no-shadow */
import React, { useRef, useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { Markers } from './markers'

const MAPBOX_API_TOKEN =
  'pk.eyJ1Ijoidm9qdGVjaGp1cmFzZWsiLCJhIjoiY2t0OXZiNGIzMWZscDJ3bjkxbHlhc2JjciJ9.1CPwh4UwNrxQGyEYdVcpmw'

const mapContainerStyle = {
  width: '100%',
  height: '400px',
}

const center = {
  lat: 49.6453028,
  lng: 18.4254839,
  zoom: 13,
}

const places = [
  {
    name: 'Frypo Wood Design s.r.o.',
    lat: 49.6453028,
    lng: 18.4254839,
  },
]

export const Map = () => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_API_TOKEN,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [center.lng, center.lat],
      zoom: center.zoom,
    })
    map.addControl(new mapboxgl.NavigationControl(), 'top-right')

    setMap(map)

    return () => map.remove()
  }, [])

  return (
    <div ref={mapContainerRef} style={mapContainerStyle}>
      {places && map && <Markers map={map} places={places} />}
    </div>
  )
}
