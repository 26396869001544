import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Box,
  Card,
  Heading,
  Link as RebassLink,
  Text,
} from 'rebass/styled-components'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Hero } from '../components/hero'
import { Map } from '../components/Map'
import { ContentSection } from '../sections/content'
import reliefBg from '../images/relief-bg.png'
import { Holiday } from '../components/holiday'

const ContactItem = ({ heading, label, text, smallText = false }) => {
  return (
    <Box sx={{ gap: '0.25rem' }}>
      <Heading variant="contactItemHeading" as="h2">
        {heading}
      </Heading>

      <Text variant="contactItemLabel" as="p">
        {label}
      </Text>

      <Text
        variant="contactItemText"
        as="p"
        sx={{ fontSize: smallText ? '1.75rem' : '', lineHeight: '2.5rem' }}
      >
        {text}
      </Text>
    </Box>
  )
}

const PhoneItemNumber = ({ label, number }) => (
  <RebassLink
    href={`tel:${number.replace(/ /gi, '')}`}
    dangerouslySetInnerHTML={{ __html: label }}
  />
)

const PhoneItem = ({ heading, phoneNumber }) => {
  return (
    <Box sx={{ gap: '0.25rem' }}>
      <Heading variant="phoneItemHeading" as="h2">
        {heading}
      </Heading>

      <Text variant="phoneItemNumber" as="p">
        {typeof phoneNumber === 'object' && !Array.isArray(phoneNumber) && (
          <PhoneItemNumber
            label={phoneNumber.label}
            number={phoneNumber.number}
          />
        )}
        {Array.isArray(phoneNumber) &&
          phoneNumber.map(({ label, number }) => (
            <PhoneItemNumber label={label} number={number} />
          ))}
      </Text>
    </Box>
  )
}

const Address = ({ title, heading, address, sx }) => {
  return (
    <Box sx={{ display: 'grid', alignContent: 'start', gap: 2, ...sx }}>
      <Heading variant="addressTitle" as="h2">
        {title}
      </Heading>

      <Text variant="addressHeading" as="p">
        {heading}
      </Text>

      <Text
        variant="addressContent"
        as="p"
        dangerouslySetInnerHTML={{ __html: address }}
      />
    </Box>
  )
}

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/contact-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Kontaktujte nás" />

      <Hero
        image={heroBg}
        bgPosition="center 16%"
        sx={{ minHeight: '50vh' }}
        title="Kontaktujte nás"
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
        boxContentSx={{
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 5,
            position: 'relative',
            mt: -6,
            width: '100%',
          }}
        >
          <Holiday />

          <Card>
            {new Date() > new Date(new Date().getFullYear(), 11) && (
              <Box
                sx={{
                  mb: 3,
                  display: 'grid',
                  gap: 0,
                  gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
                }}
              >
                <ContactItem
                  heading="Provozní doba do konce roku 2022"
                  label="16.12.2022"
                  text="7:30-14:30"
                />
                <ContactItem
                  heading="&nbsp;"
                  label="19.12.2022-1.1.2023"
                  text="zavřeno"
                />
                <ContactItem
                  heading="&nbsp;"
                  label="Od 2.1.2023"
                  text="7:30-14:30"
                  smallText
                />
              </Box>
            )}
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: ['row', 'column'],
                gap: 4,
                alignItems: 'center',
                justifyItems: 'space-evenly',
                width: '100%',
              }}
            >
              <ContactItem
                heading="Pracovní doba"
                label="Pondělí—Pátek"
                text="07:30—14:30"
              />
              <ContactItem
                heading="Telefonické objednávky a konzultace"
                label="Pondělí—Pátek"
                text="07:30—16:00"
              />
              <ContactItem
                heading="Potřebujete poradit?"
                label="E-mail"
                text={
                  <RebassLink href="mailto:info@frypo.cz">
                    info@frypo.cz
                  </RebassLink>
                }
              />
            </Box>
          </Card>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto', 'repeat(2, 1fr)'],
            gridTemplateRows: ['auto', 'repeat(2, 1fr)'],
            gap: 3,
            columnGap: 5,
            minWidth: '100%',
          }}
        >
          <PhoneItem
            heading="Objednávky mořidel, laků a barev"
            phoneNumber={[
              {
                label: '+420 <strong>558 437 498</strong>',
                number: '+420558437498',
              },
              {
                label: '+420 <strong>774 426 811</strong>',
                number: '+420774426811',
              },
            ]}
          />
          <PhoneItem
            heading="Vedoucí prodeje laků a technická podpora"
            phoneNumber={{
              label: '+420 <strong>602 712 725</strong>',
              number: '+420602712725',
            }}
          />
          <PhoneItem
            heading="Podpora prodeje"
            phoneNumber={{
              label: '+420 <strong>602 846 292</strong>',
              number: '+420602846292',
            }}
          />
          <PhoneItem
            heading="IT specialista"
            phoneNumber={{
              label: '+420 <strong>724 771 804</strong>',
              number: '+420724771804',
            }}
          />
        </Box>
      </ContentSection>

      <ContentSection
        sx={{
          py: 0,
          position: 'relative',
          bg: 'transparent',
          zIndex: 1,
        }}
        boxContentSx={{
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto', 'repeat(2, 1fr)'],
            width: '100%',
          }}
        >
          <Card sx={{ gap: 4 }}>
            <Address
              title="Provozní adresa"
              heading="vyzvednutí balíčků a kanceláře"
              address="<strong>FRYPO wood design s.r.o.</strong>
            <br>Skalice 439<br>738 01, Frýdek-Místek – Skalice"
            />

            <RebassLink
              variant="linkButton"
              href="https://mapy.cz/s/bamobezate"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zobrazit na mapě
            </RebassLink>
          </Card>

          <Address
            sx={{ p: 4 }}
            title="Fakturační a korespondenční adresa"
            heading="IČ: 04188608, DIČ: CZ04188608"
            address="<strong>FRYPO wood design s.r.o.</strong>
          <br>Sokola Tůmy 1593<br>739 11 Frýdlant nad Ostravicí"
          />
        </Box>
      </ContentSection>

      <Box sx={{ mt: [0, '-84px'] }}>
        <Map />
      </Box>
    </Layout>
  )
}

export default ContactPage

ContactItem.propTypes = {
  heading: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  smallText: PropTypes.bool,
}

PhoneItem.propTypes = {
  heading: PropTypes.string.isRequired,
  phoneNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
}

PhoneItemNumber.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
}

Address.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  sx: PropTypes.object,
}
