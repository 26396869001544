import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'

import MapPin from '../../images/map-pin.svg'

const Marker = ({ map, place }) => {
  const markerRef = useRef()

  useEffect(() => {
    const el = document.createElement('div')
    const width = 36
    const height = 48
    el.className = 'marker'
    el.style.backgroundImage = `url(${MapPin})`
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    el.style.backgroundSize = '100%'

    const marker = new mapboxgl.Marker(el, {
      offset: [0, -24],
    })
      .setLngLat([place.lng, place.lat])
      .addTo(map)

    return () => marker.remove()
  })

  return <div ref={markerRef} />
}

export const Markers = ({ map, places }) => {
  return (
    <>
      {places &&
        places.map((place) => (
          <Marker key={place.name} map={map} place={place} />
        ))}
    </>
  )
}

Marker.propTypes = {
  map: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
}

Markers.propTypes = {
  map: PropTypes.object.isRequired,
  places: PropTypes.array.isRequired,
}
